import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { addUrlParam } from '~/utils'
import { ActionText } from './ActionText'
import { Gradient } from '../../gradients'
import { Button, type ButtonProps, type ButtonRef } from './Button'
import {
  RoutedLink,
  type RoutedLinkProps,
  type RoutedLinkRef,
} from './RoutedLink'
import {
  ExternalLink,
  type ExternalLinkProps,
  type ExternalLinkRef,
} from './ExternalLink'
import { env } from '~/env'

type RoutedLinkType = Omit<RoutedLinkProps, 'hasChildren'> & {
  blank?: never
  href: string
  backURL: string
  pageTitle: string
}
type ExternalLinkType = Omit<ExternalLinkProps, 'hasChildren'> & {
  href: string
  blank: boolean
  backURL?: never
  pageTitle?: never
}
type ButtonType = Omit<ButtonProps, 'hasChildren'> & {
  href?: never
  blank?: never
  backURL?: never
  pageTitle?: never
}

/**
 * A simplified version of `ActionBaseProps` for cases where it's hard for
 * Typescript to determine the type you intend. This is especially useful for
 * Storybook and components that extend `Action` by wrapping their contents in an
 * Action and passing a `rest` prop. In this version, required or never props
 * are all optional.
 */
export type LooseActionBaseProps = (
  | Omit<
      RoutedLinkProps,
      'href' | 'backURL' | 'pageTitle' | 'onPress' | 'hasChildren'
    >
  | Omit<ExternalLinkProps, 'href' | 'onPress' | 'hasChildren'>
  | Omit<ButtonProps, 'onPress' | 'hasChildren'>
) & {
  href?: string
  blank?: boolean
  backURL?: string
  pageTitle?: string
  onPress?: (e: GestureResponderEvent) => void
}

export type ActionBaseProps = RoutedLinkType | ExternalLinkType | ButtonType

export type ActionBaseRef = ButtonRef | RoutedLinkRef | ExternalLinkRef

/**
 * A basic link/button component without analytics integration. Generally you
 * should prefer using `Action` which includes analytics tracking.
 */
export const ActionBase = React.forwardRef<ActionBaseRef, ActionBaseProps>(
  (
    {
      href,
      blank,
      backURL,
      pageTitle,
      look: lookProp,
      onPress,
      disabled,
      feel,
      transparent,
      position,
      icon,
      iconPosition = 'start',
      children,
      'aria-disabled': ariaDisabled,
      ...rest
    },
    ref,
  ) => {
    const isLink = !!href
    const look = lookProp || (isLink ? 'link' : 'button')
    const showGradient = look === 'button' && feel === 'primary'
    const hasChildren = !!children

    // If href has backURL or pageTitle params, we want to remove them because
    // we are going to add them from the props
    // I can't actually do this because it breaks out DateSelect because it needs to update
    // the URL (which has backURL and pageTitle) when the date changes.
    // if (href?.includes('backURL')) href = removeUrlParam(href, 'backURL')
    // if (href?.includes('pageTitle')) href = removeUrlParam(href, 'pageTitle')

    // Append backURL and pageTitle to href
    if (href && backURL) href = addUrlParam(href, 'backURL', backURL)
    if (href && pageTitle) href = addUrlParam(href, 'pageTitle', pageTitle)

    // Props used by the DefaultText and Action
    const sharedProps = {
      look,
      feel,
      disabled,
      transparent,
      position: showGradient ? 'relative' : position,
      icon,
      iconPosition,
    }

    const buttonProps = {
      'aria-disabled': ariaDisabled || disabled,
      onPress: (e: GestureResponderEvent) => {
        if (onPress && !disabled) {
          onPress(e)
        } else if (env.test) {
          if (disabled) console.warn('Attempted to press on a disabled button.')
          else
            console.warn(
              'No onPress function provided to the button you tried to press on.',
            )
        }
      },
      hasChildren,
      ...sharedProps,
      ...rest,
    }

    const textProps = {
      ...sharedProps,
    }

    if (typeof children === 'string') {
      children = <ActionText {...textProps}>{children}</ActionText>
    }

    if (icon) {
      children = (
        <>
          {iconPosition !== 'end' &&
            React.cloneElement(icon, { mr: hasChildren ? 'xs' : 0 })}
          {children}
          {iconPosition === 'end' &&
            React.cloneElement(icon, { ml: hasChildren ? 'xs' : 0 })}
        </>
      )
    }

    if (showGradient) {
      children = (
        <>
          <Gradient
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex="below"
          />
          {children}
        </>
      )
    }

    if (isLink) {
      if (blank) {
        return (
          <ExternalLink
            href={href}
            blank={blank}
            children={children}
            {...buttonProps}
            ref={ref}
          />
        )
      } else {
        return (
          <RoutedLink
            href={href}
            children={children}
            {...buttonProps}
            ref={ref}
          />
        )
      }
    } else {
      return <Button children={children} {...buttonProps} ref={ref} />
    }
  },
)
