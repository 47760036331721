import React from 'react'
import { Platform } from 'react-native'
import { useTimeout } from '@thesoulfresh/react-tools'

import appJSON from '~/../app.json'
import {
  analyticsLogger as logger,
  createClient,
  SegmentClient,
} from './segment'
import { env } from '~/env'
import { getRoute } from '~/routes'
import { useLocation } from '~/navigation'
import { User } from '../report-api'
import { useIsFirstRender } from '~/hooks/useIsFirstRender'
import {
  AnalyticsEventNames,
  AnalyticsEventOptions,
  AnalyticsEvents,
} from './event-config'

/* istanbul ignore next: only enabled outside of the test env */
function debugLog(
  type: string,
  summary: string,
  args: Record<string, string | number>,
) {
  const l = Platform.OS === 'web' ? console : logger
  if (!env.test && env.verbose) {
    l.groupCollapsed(
      `%c[AnalyticsService] %c${type} %c${summary} %c(tracking disabled)`,
      'color: #f44c7f; font-weight: bold;',
      'color: initial; font-weight: bold;',
      'color: #f44c7f; font-weight: bold;',
      'color: #aaa; font-weight: normal;',
    )
    for (const prop in args) {
      l.log(
        `%c${prop}: %c${args[prop]}`,
        'color: #aaa, font-weight: bold',
        'color: initial; font-weight: normal;',
      )
    }
    l.groupEnd()
  }
}

const AnalyticsContext = React.createContext<AnalyticsService>(null)
export const AnalyticsProvider = AnalyticsContext.Provider

/**
 * Use the analytics API to track pages or events.
 */
export const useAnalytics = () => {
  const location = usePageURL()
  const analytics = React.useContext(AnalyticsContext)

  return React.useMemo(
    () => ({
      track: (name: AnalyticsEventNames, options: AnalyticsEventOptions) => {
        analytics.track(name, {
          ...location,
          ...options,
        })
      },
      screen: (name: string) =>
        analytics.screen(name, {
          ...location,
        }),
      /**
       * Resets the analytics context when the user logs out.
       */
      reset: analytics.reset,
    }),
    [analytics, location],
  )
}

export interface AnalyticsPageviewProps {
  path: string | 'unknown'
  search: string | 'unknown'
}

interface AnalyticsIdentifyProps {
  email: string
  name: string
  nickname: string
  createdAt: string
  roles: string[]
  title: string
  locale: string
  avatar: string
  company: AnalyticsGroupProps
  dateOfHire: string
  personaName: string
}

interface AnalyticsGroupProps {
  id: string
  name: string
  plan: string
  industry: string
  createdAt: string
}

export type AnalyticsEventProps = AnalyticsPageviewProps &
  Record<string, string | number | boolean>

function MakeUnroutedTracker<Options>(
  type: string,
  analytics: ReturnType<typeof createClient>,
) {
  return (event: string, options: Options) => {
    analytics[type](event, {
      platform: getPlatform(),
      jsVersion: env.jsVersion,
      appVersion: env.appVersion,
      ...options,
    })
  }
}

function MakeUnroutedPagedTracker<Options extends AnalyticsPageviewProps>(
  type: string,
  analytics: ReturnType<typeof createClient>,
) {
  return (event: string, { path, search, ...rest }: Options) => {
    const origin = getPageOrigin()
    analytics[type](event, {
      platform: getPlatform(),
      jsVersion: env.jsVersion,
      appVersion: env.appVersion,
      origin,
      url:
        origin +
        (path === 'unknown' ? '/' : path) +
        (search === 'unknown' ? '' : search),
      path,
      search,
      ...rest,
    })
  }
}

export interface AnalyticsService {
  track: ReturnType<typeof MakeUnroutedTracker<AnalyticsEventProps>>
  screen: ReturnType<typeof MakeUnroutedPagedTracker>
  identify: ReturnType<typeof MakeUnroutedTracker<AnalyticsIdentifyProps>>
  group: ReturnType<typeof MakeUnroutedTracker<AnalyticsGroupProps>>
  alias: ReturnType<typeof MakeUnroutedTracker>
  reset: () => void
}

export function createAnalyticsService(
  client?: AnalyticsService,
  enabled = env.analyticsEnabled,
  debug = env.verbose,
) {
  if (client) return client

  // Create the Segment instance
  const analytics = (() => {
    if (enabled) {
      clientCount += 1
      /* istanbul ignore next */
      if (env.verbose)
        logger.info('Creating AnalyticsService with key', env.segmentWriteKey)
      /* istanbul ignore next */
      if (!env.test && !env.production && clientCount > 1) {
        logger.error(`Created AnalyticsService ${clientCount} times`)
      }

      const out = createClient({
        writeKey: env.segmentWriteKey,
        debug,
      })

      return out
    } else {
      /* istanbul ignore next */
      if (env.verbose) logger.info('Tracking disabled')
      // If analytics is disabled, provide a stub implementation so we don't
      // throw errors trying to access the analytics client.
      // prettier-ignore
      return {
        track:    /* istanbul ignore next */ (event: string, args: any) => debugLog(`TRACK`, event, args),
        screen:   /* istanbul ignore next */ (title: string, args: any) => debugLog('SCREEN', title, args),
        identify: /* istanbul ignore next */ (_id: string, args: any) => debugLog('IDENTIFY', args.name, args),
        group:    /* istanbul ignore next */ (_id: string, args: any) => debugLog('GROUP', args.name, args),
        alias:    /* istanbul ignore next */ (args: any) => debugLog('ALIAS', args.name, args),
        reset:    /* istanbul ignore next */ () => debugLog('RESET', '', undefined),
      } as any as SegmentClient
    }
  })()

  // Wrap the Segment instance in our own facad that enforces our requirements.
  const out = {
    track: MakeUnroutedPagedTracker<AnalyticsEventProps>('track', analytics),
    screen: MakeUnroutedPagedTracker('screen', analytics),
    identify: MakeUnroutedTracker<AnalyticsIdentifyProps>(
      'identify',
      analytics,
    ),
    group: MakeUnroutedTracker<AnalyticsGroupProps>('group', analytics),
    alias: MakeUnroutedTracker('alias', analytics),
    reset: () => analytics.reset(),
  }
  return out
}

let clientCount = 0
/**
 * Generate the analytics client to use for the app. This should only be called
 * once in the app to initialize the analytics.
 *
 * ie.
 *  const analyticsService = useCreateAnalyticsService()
 *  analyticsService.screen('Login Page', {
 *    component: 'LastLineOfDefence',
 *    path: getPageOrigin(),
 *    platform: getPlatform(),
 *    ...etc
 *  })
 *
 *  More info on the different specs can be found here:
 *  https://segment.com/docs/connections/spec/
 */
export function useCreateAnalyticsService(
  client?: AnalyticsService,
  enabled?: boolean,
) {
  return React.useMemo(
    () => createAnalyticsService(client, enabled),
    [client, enabled],
  )
}

/**
 * Mimic the native "Application Opened/Backgrounded" events on web.
 */
export function useTrackBrowserFocus(
  /**
   * The amount of time that the browser must retain focus in order to be
   * considered an "Open" event. This ensures that if the user unintentionally
   * focuses the browser (for example, while switching desktops or apps), that
   * we don't send false open events.
   */
  focusDelay = 5000,
) {
  // `useTrackBrowserFocus` will be called outside of the Router so we can't
  // `useAnalytics` here.
  const { track } = React.useContext(AnalyticsContext)
  const firstRender = useIsFirstRender()
  const wait = useTimeout()

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      let timeout: number

      // Application focused events don't occur on first load so we'll mimic the
      // initial load here.
      if (firstRender) {
        track(AnalyticsEvents.application_open.name, {
          ...AnalyticsEvents.application_open.build(!firstRender),
          // Since we are not wrapped in a Router Context, these are unknown.
          path: 'unknown',
          search: 'unknown',
        })
      }

      const onStateChange = () => {
        if (window?.document?.visibilityState === 'visible') {
          timeout = wait(() => {
            timeout = null
            track(AnalyticsEvents.application_open.name, {
              ...AnalyticsEvents.application_open.build(true),
              // Since we are not wrapped in a Router Context, these are unknown.
              path: 'unknown',
              search: 'unknown',
            })
          }, focusDelay)
        }
        // Browser Blur
        else {
          // If we lose focus before the timeout, then don't consider this an
          // open/close and instead ignore both events.
          if (timeout) {
            // This will prevent the application opened event from firing.
            clearTimeout(timeout)
          }
          // If there is no timeout, then we already sent an opened event and we
          // should send the closed event.
          else {
            track(AnalyticsEvents.application_background.name, {
              ...AnalyticsEvents.application_background.build(),
              // Since we are not wrapped in a Router Context, these are unknown.
              path: 'unknown',
              search: 'unknown',
            })
          }
        }
      }

      window?.document?.addEventListener('visibilitychange', onStateChange)
      return () =>
        window?.document?.removeEventListener('visibilitychange', onStateChange)
    }
  })
}

/**
 * Associate the current viewing session with a user. This call should only be
 * made once a session, and should be made as soon as the user is known.
 */
export function useTrackUser(user: User) {
  const { identify, group } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (user) {
      // Join plan names in case there are more than one (unlikely).
      const planNames = user.accessibleFeatures.map((f) => f.planName)
      const plans = Array.from(new Set(planNames)).join(', ') //Removes dups

      const company = {
        id: user.ownershipGroup.id,
        name: user.ownershipGroup.name,
        plan: plans || 'basic',
        industry: 'real estate',
        createdAt: user.ownershipGroup.createdAt,
      }

      // Identify the user
      identify(user.id, {
        email: user.email,
        name: user.name,
        nickname: user.nickname,
        createdAt: user.createdAt,
        roles: user.allowedRoles,
        title: user.title,
        locale: user.locale,
        avatar: user.picture,
        company: company,
        dateOfHire: user.dateOfHire,
        personaName: user.personaName,
      })
      // Associate the user with their ownership group
      group(company.id, company)
    }
  }, [user, identify, group])
}

/**
 * Get the URL origin for the current page. This will be something like
 * "http://localhost:19006" or "https://apartmentsnapshot.com" on web and
 * "apartmentsnapshot://" on native.
 */
export function getPageOrigin() {
  const webOrigin = window?.location?.origin
  const out =
    Platform.OS === 'web' ? (webOrigin ?? '') : `${appJSON.expo.scheme}://`
  return out
}

/**
 * Get the app type for the current session. This will only ever be "web" or
 * "native" and helps us differentiate between the two. The analytics tool will
 * track ios vs android independently.
 */
export function getPlatform() {
  return Platform.OS === 'web' ? 'web' : 'native'
}

const trimTrailingSlash = (str: string) => str.replace(/\/$/, '')

/**
 * Get a fully qualified URL for the current page. For native devices, this will
 * be prefixed with the app's scheme (e.g. "apartmentsnapshot://").
 */
export function usePageURL() {
  const location = useLocation()
  const origin = getPageOrigin()

  return React.useMemo(() => {
    // Sometimes redirects leave a trailing slash on the path. For consistency,
    // we ensure the trailing slash is always removed. We also don't include the
    // search or hash in the path because that allows us to track page views and
    // page filtering independently.
    const path = trimTrailingSlash(location.pathname)
    const url = `${origin}${path}${location.hash}${location.search}`
    return {
      origin,
      path,
      url,
      search: location.search,
    }
  }, [location.hash, location.pathname, location.search, origin])
}

/**
 * Track a page view.
 */
export function useTrackPage(
  /**
   * The route identifier from the routes file. This is the same ID passed to
   * `getURL`
   */
  routeName: string,
) {
  const route = getRoute(routeName)
  const { screen } = useAnalytics()
  const analyticsId = route?.analyticsId

  React.useEffect(() => {
    if (!analyticsId) {
      /* istanbul ignore next */
      logger.error(`No tracking id configured for page ${analyticsId}`)
      return
    }

    // This will retrigger on page URL changes because `useAnalytics` calls
    // `usePageURL()`.
    screen(analyticsId)
  }, [analyticsId, screen])
}
